(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/payment/payment/assets/context/create-payment-response-api.js') >= 0) return;  svs.modules.push('/components/payment/payment/assets/context/create-payment-response-api.js');
'use strict';

const createPaymentResponseApi = (paymentService, paymentContextApi) => {
  const {
    getUnpaidPayableItems,
    getPaidPayableItems,
    getPaidPayableItemsDTO,
    getPaidAmount
  } = paymentContextApi;
  const paidPayableItemsDTO = getPaidPayableItemsDTO();

  const getTransactionIds = () => {
    const transactionIds = paidPayableItemsDTO.map(dto => dto.transactionId);
    return transactionIds;
  };
  const paymentResult = {
    getUnpaidPayableItems,
    getPaidPayableItems,
    getPaidPayableItemsDTO,
    getPaidAmount,
    getTransactionIds
  };
  return Object.freeze(paymentResult);
};
setGlobal('svs.components.payment.payment.context.createPaymentResponseApi', createPaymentResponseApi);

 })(window);