(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/payment/payment/assets/context/create-payment-context-api.js') >= 0) return;  svs.modules.push('/components/payment/payment/assets/context/create-payment-context-api.js');
'use strict';

function _awaitAsyncGenerator(e) { return new _OverloadYield(e, 0); }
function _wrapAsyncGenerator(e) { return function () { return new AsyncGenerator(e.apply(this, arguments)); }; }
function AsyncGenerator(e) { var r, t; function resume(r, t) { try { var n = e[r](t), o = n.value, u = o instanceof _OverloadYield; Promise.resolve(u ? o.v : o).then(function (t) { if (u) { var i = "return" === r ? "return" : "next"; if (!o.k || t.done) return resume(i, t); t = e[i](t).value; } settle(n.done ? "return" : "normal", t); }, function (e) { resume("throw", e); }); } catch (e) { settle("throw", e); } } function settle(e, n) { switch (e) { case "return": r.resolve({ value: n, done: !0 }); break; case "throw": r.reject(n); break; default: r.resolve({ value: n, done: !1 }); } (r = r.next) ? resume(r.key, r.arg) : t = null; } this._invoke = function (e, n) { return new Promise(function (o, u) { var i = { key: e, arg: n, resolve: o, reject: u, next: null }; t ? t = t.next = i : (r = t = i, resume(e, n)); }); }, "function" != typeof e.return && (this.return = void 0); }
AsyncGenerator.prototype["function" == typeof Symbol && Symbol.asyncIterator || "@@asyncIterator"] = function () { return this; }, AsyncGenerator.prototype.next = function (e) { return this._invoke("next", e); }, AsyncGenerator.prototype.throw = function (e) { return this._invoke("throw", e); }, AsyncGenerator.prototype.return = function (e) { return this._invoke("return", e); };
function _OverloadYield(e, d) { this.v = e, this.k = d; }
const {
  CollectionError
} = svs.components.payment.common.error;
const {
  logger
} = svs.components.payment.common;

const createPaymentContextApi = contextApi => {
  const _contextPayableItems = contextApi.payment.getPayableItems(); 
  const _payableItems = [..._contextPayableItems];
  let _unpaidPayableItems = [..._contextPayableItems];
  const _paidPayableItems = [];
  let _accountBalance = null;
  const _paymentError = new CollectionError();


  const mergeCollectionError = collectionError => {
    if (!(collectionError instanceof CollectionError)) {
      return false;
    }
    for (const error of collectionError.errors) {
      _paymentError.addError(error);
    }
    return true;
  };

  const addPaymentError = error => {
    _paymentError.addError(error);
  };

  const getPaymentError = () => {
    const errors = _paymentError.errors;
    if (!errors.length) {
      return null;
    }
    return _paymentError;
  };


  const addPaidItem = (payable, dto) => {
    var _dto$accountBalance;
    _unpaidPayableItems = _unpaidPayableItems.filter(unpaidPayable => unpaidPayable !== payable);
    _accountBalance = (_dto$accountBalance = dto.accountBalance) !== null && _dto$accountBalance !== void 0 ? _dto$accountBalance : _accountBalance;
    _paidPayableItems.push({
      payable,
      dto
    });
  };

  const addPaidItems = payableResponses => {
    for (const {
      payable,
      dto
    } of payableResponses) {
      addPaidItem(payable, dto);
    }
  };

  const getPayableItems = () => _payableItems;

  const getUnpaidPayableItems = () => Object.freeze(_unpaidPayableItems);

  const getPaidPayableItems = () => _paidPayableItems.map(_ref => {
    let {
      payable
    } = _ref;
    return payable;
  });

  const getPaidPayableItemsDTO = () => _paidPayableItems.map(_ref2 => {
    let {
      dto
    } = _ref2;
    return dto;
  });

  const getPaidPayableItemsData = () => Object.freeze(_paidPayableItems);

  const getPaidAmount = () => _paidPayableItems.map(_ref3 => {
    let {
      dto
    } = _ref3;
    return dto.cost;
  }).reduce((sum, cost) => sum + cost, 0);

  const getUnpaidAmount = () => _unpaidPayableItems.reduce((sum, wager) => sum + wager.bets.reduce((sum, bet) => sum + bet.price, 0), 0);

  const getTotalPrice = () => contextApi.payment.getTotalPrice();

  const getAccountBalance = () => _accountBalance;

  function createPaymentsIterable() {
    const itemsToPay = [..._unpaidPayableItems];
    const totalLength = itemsToPay.length;
    let count = 0;
    const failedPayableItems = [];

    return Object.freeze({
      get length() {
        return totalLength;
      },
      get count() {
        return count;
      },
      get remaining() {
        return [...failedPayableItems, ...itemsToPay];
      },
      [Symbol.asyncIterator]() {
        return _wrapAsyncGenerator(function* () {
          while (itemsToPay.length > 0) {
            const payable = itemsToPay.shift();
            count++;
            try {
              yield _awaitAsyncGenerator(contextApi.payment.tasks.run(payable.localId));
            } catch (error) {
              var _error$severity;
              const logLevel = (_error$severity = error.severity) !== null && _error$severity !== void 0 ? _error$severity : 'warn';
              logger[logLevel]("Payment failed on task at ".concat(count, " of ").concat(totalLength, ": ").concat(error));
              addPaymentError(error);
              failedPayableItems.push(payable);

              continue;
            }
            yield payable;
          }
        })();
      }
    });
  }
  const paymentApi = {
    mergeCollectionError,
    addPaymentError,
    getPaymentError,
    addPaidItem,
    addPaidItems,
    getPayableItems,
    getUnpaidPayableItems,
    getPaidPayableItems,
    getPaidPayableItemsDTO,
    getPaidPayableItemsData,
    getPaidAmount,
    getUnpaidAmount,
    getTotalPrice,
    getAccountBalance,
    createPaymentsIterable
  };
  return Object.freeze(paymentApi);
};
setGlobal('svs.components.payment.payment.context.createPaymentContextApi', createPaymentContextApi);

 })(window);