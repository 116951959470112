(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/payment/payment/assets/create-payment-info.js') >= 0) return;  svs.modules.push('/components/payment/payment/assets/create-payment-info.js');

'use strict';

const createPaymentInfo = () => {
  const paymentInfo = [];
  const add = _ref => {
    let {
      message,
      localId,
      type,
      extraData
    } = _ref;
    const paymentInfoData = {
      message,
      localId,
      type,
      extraData
    };
    paymentInfo.push(paymentInfoData);
  };
  const get = () => paymentInfo;
  const context = {
    add,
    get
  };
  return Object.freeze(context);
};
setGlobal('svs.components.payment.payment.createPaymentInfo', createPaymentInfo);

 })(window);