(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/payment/payment/assets/create-tasks.js') >= 0) return;  svs.modules.push('/components/payment/payment/assets/create-tasks.js');

'use strict';

const createTasks = () => {
  const itemTasks = {};
  const add = (localItemId, task) => {
    itemTasks[localItemId] = (itemTasks[localItemId] || []).concat(task);
  };
  const run = localItemId => {
    const tasks = itemTasks[localItemId] || [];
    return Promise.all(tasks.map(task => task()));
  };
  const context = {
    add,
    run
  };
  return Object.freeze(context);
};
setGlobal('svs.components.payment.payment.createTasks', createTasks);

 })(window);