(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/payment/payment/assets/create-payment.js') >= 0) return;  svs.modules.push('/components/payment/payment/assets/create-payment.js');
'use strict';

const {
  pipeline,
  subject
} = svs.components.payment.common.utils;
const {
  CollectionError,
  PaymentError
} = svs.components.payment.common.error;
const {
  PaymentService
} = svs.components.payment.servicePayment;
const {
  logger
} = svs.components.payment.common;
const {
  createPaymentContextApi,
  createMiddlewareContextApi,
  createPaymentResponseApi
} = svs.components.payment.payment.context;

const createPayment = paymentService => {
  if (!(paymentService instanceof PaymentService)) {
    throw new Error('PaymentService is required');
  }
  const payableItems = [];
  const obj = {};
  const observers = {};
  const preMiddlewares = pipeline();
  const postMiddlewares = pipeline();

  let proxyObj;
  let isPendingPayment = false;
  let isPaymentInitializedOnce = false;

  ['onStart', 'onFinally'].forEach(method => {
    observers[method] = subject();
    obj[method] = observer => {
      observers[method].subscribe(observer);
      return proxyObj;
    };
  });

  const tryPay = async userContext => {
    const [mwContextApi, updateMwContextApi] = createMiddlewareContextApi(payableItems, observers, userContext);
    let paymentError;
    let paymentContextApi;
    try {
      logger.info("Payment start: PC:".concat(mwContextApi.payment.count(), " C:").concat(mwContextApi.payment.getTotalPrice()));
      isPendingPayment = true;
      observers.onStart.publish();
      await preMiddlewares.execute(mwContextApi);
      paymentContextApi = createPaymentContextApi(mwContextApi);
      const [paymentResults, _paymentError] = await paymentService.initiatePaymentRequest(paymentContextApi.createPaymentsIterable());

      paymentContextApi.mergeCollectionError(_paymentError);
      paymentError = paymentContextApi.getPaymentError();
      paymentContextApi.addPaidItems(paymentResults);
      const successfulPayableItemsCount = paymentContextApi.getPaidPayableItems().length;
      updateMwContextApi.payment.setPaidCount(successfulPayableItemsCount);
    } catch (error) {
      paymentError = error;
    } finally {
      paymentContextApi = paymentContextApi || createPaymentContextApi(mwContextApi);
      if (paymentError instanceof PaymentError) {
        logger.info("Payment exited: ".concat(paymentError));
      } else if (paymentError && !(paymentError instanceof CollectionError)) {
        logger.warn("Unhandled exception: ".concat(paymentError.message));
      }
      const totalPayableItems = payableItems.length;
      const successfulPayableItems = paymentContextApi.getPaidPayableItems().length;
      if (successfulPayableItems) {
        logger.info("Payment done: ".concat(successfulPayableItems, "(").concat(totalPayableItems, ")"));
      }
      paymentService.onPaymentDone(paymentContextApi.getPaidPayableItemsData());

      setTimeout(() => {
        paymentService.trackPayments(paymentContextApi.getPaidPayableItemsData(), userContext);
      });
      isPendingPayment = false;
    }
    const response = [createPaymentResponseApi(paymentService, paymentContextApi), paymentError];
    updateMwContextApi.payment.updatePaymentResponse(response);
    try {
      postMiddlewares.execute(mwContextApi);
    } catch (err) {
      logger.warn("Post middleware failed msg: ".concat(err === null || err === void 0 ? void 0 : err.message, ", ").concat(err));
    }
    observers.onFinally.publish(...response);
    return response;
  };

  obj.execute = function () {
    let {
      userContext = {}
    } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    isPaymentInitializedOnce = true;
    if (isPendingPayment) {
      logger.info('Payment already initialized');
      return proxyObj;
    }
    if (!payableItems.length) {
      throw new PaymentError({
        clientCode: PaymentError.ClientErrorCodes.NOT_ENOUGH_DATA
      });
    }

    return tryPay(userContext);
  };

  obj.addPreMiddleware = function () {
    preMiddlewares.push(...arguments);
    return proxyObj;
  };

  obj.addPostMiddleware = function () {
    postMiddlewares.push(...arguments);
    return proxyObj;
  };

  obj.addPayable = function () {
    paymentService.assertPayable(...arguments);
    payableItems.push(...arguments);
    return proxyObj;
  };
  proxyObj = new Proxy(obj, {
    set() {},
    get(target, prop) {
      if (isPaymentInitializedOnce && ['addPreMiddleware', 'addPostMiddleware'].includes(prop)) {
        throw new Error('Not allowed to add middleware after payment initialization');
      }
      return Reflect.get(...arguments);
    }
  });
  return proxyObj;
};
setGlobal('svs.components.payment.payment.createPayment', createPayment);

 })(window);