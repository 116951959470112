(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/payment/payment/assets/context/create-middleware-context-api.js') >= 0) return;  svs.modules.push('/components/payment/payment/assets/context/create-middleware-context-api.js');
'use strict';

const {
  createPaymentInfo,
  createTasks
} = svs.components.payment.payment;

const createPayableContextApi = payableItems => {
  const _payableItems = Object.freeze([...payableItems]);
  let paidPayableCount = 0;
  const getTotalPrice = () => _payableItems.reduce((sum, payable) => sum + payable.price, 0);
  const count = () => _payableItems.length;
  const getPayableItems = () => _payableItems;
  const getUnpaidCount = () => _payableItems.length - paidPayableCount;
  const getPayableType = () => {
    const type = _payableItems[0].type;
    const isSameType = _payableItems.every(payable => payable.type === type);
    if (!isSameType) {
      throw new Error('All payable items must be of the same type');
    }
    return type;
  };
  const context = Object.freeze({
    getTotalPrice,
    count,
    getPayableItems,
    getPayableType,
    getUnpaidCount,
    tasks: createTasks(),
    paymentResponse: {}
  });
  const setPaidCount = count => {
    paidPayableCount = count;
  };
  const updatePaymentResponse = response => {
    context.paymentResponse.result = response[0];
    if (response[1] && !response[1].handledError) {
      context.paymentResponse.error = response[1];
    }
  };
  const updateContext = Object.freeze({
    setPaidCount,
    updatePaymentResponse
  });
  return [context, updateContext];
};

const createMiddlewareContextApi = function (payableItems, observers) {
  let userContext = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  const [payableContextApi, payableUpdateContextApi] = createPayableContextApi(payableItems);
  const context = Object.freeze({
    payment: payableContextApi,
    observers,
    paymentInfo: createPaymentInfo(),
    userContext
  });
  const updateContext = Object.freeze({
    payment: payableUpdateContextApi
  });
  return [context, updateContext];
};
setGlobal('svs.components.payment.payment.context.createMiddlewareContextApi', createMiddlewareContextApi);

 })(window);